import { motion, useInView } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { isChrome, isChromium, isEdgeChromium, isSafari } from 'react-device-detect';
import { isEdge } from 'slate';

import styles from './CardStackingSection.module.scss';

type CardStackingSectionProps = {
  id: string;
  maxWidth: number;
  t: any;
};

const CardStackingSection = ({ id, maxWidth, t }: CardStackingSectionProps) => {
  const refVideoCard1 = useRef(null);
  const isInViewVideoCard1 = useInView(refVideoCard1, {
    amount: 'all',
  });
  useEffect(() => {
    if (isInViewVideoCard1) {
      refVideoCard1.current.play();
    } else {
      refVideoCard1.current.pause();
    }
  }, [isInViewVideoCard1]);

  const refVideoCard2 = useRef(null);
  const isInViewVideoCard2 = useInView(refVideoCard2, {
    amount: 'all',
  });
  useEffect(() => {
    if (isInViewVideoCard2) {
      refVideoCard2.current.play();
    } else {
      refVideoCard2.current.pause();
    }
  }, [isInViewVideoCard2]);

  const refVideoCard3 = useRef(null);
  const isInViewVideoCard3 = useInView(refVideoCard3, {
    amount: 'all',
  });
  useEffect(() => {
    if (isInViewVideoCard3) {
      refVideoCard3.current.play();
    } else {
      refVideoCard3.current.pause();
    }
  }, [isInViewVideoCard3]);

  const refVideoCard4 = useRef(null);
  const isInViewVideoCard4 = useInView(refVideoCard4, {
    amount: 'all',
  });
  useEffect(() => {
    if (isInViewVideoCard4) {
      refVideoCard4.current.play();
    } else {
      refVideoCard4.current.pause();
    }
  }, [isInViewVideoCard4]);

  // allow proper source of video to be loaded according to device browser type
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isSafari) {
        refVideoCard1.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/Customize your website.mp4`;
        refVideoCard2.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/Planning.mp4`;
        refVideoCard3.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/RSVP and Invitations.mp4`;
        refVideoCard4.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/Manage your guests.mp4`;
      } else if (isChromium || isChrome || isEdge || isEdgeChromium) {
        refVideoCard1.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/Customize your website.webm`;
        refVideoCard2.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/Planning.webm`;
        refVideoCard3.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/RSVP and Invitations.webm`;
        refVideoCard4.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/Manage your guests.webm`;
      } else {
        refVideoCard1.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/Customize your website.mp4`;
        refVideoCard2.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/Planning.mp4`;
        refVideoCard3.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/RSVP and Invitations.mp4`;
        refVideoCard4.current.src = `${process.env.CDN_PROVIDER_URL}/newassets/video/Manage your guests.mp4`;
      }
    }, 5000); // Delay in milliseconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <section id={id} className={styles.cardStacking}>
      <div className={styles.wrapper}>
        <div className={styles.card}>
          {maxWidth <= 1200 ? ( // tablet and phone
            <>
              <h3 className={'fridayH3'}>{t.weddingPlannerSection1}</h3>
              <img
                className={styles.weddingPlanningPhoto}
                loading="lazy"
                src={`${process.env.CDN_PROVIDER_URL}/newassets/wedding_planner_card.jpg`}
                alt="wedding_planner_card"
              />
              <p className={'didacticP'}>{t.weddingPlannerSection2}</p>
            </>
          ) : (
            // desktop
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: '100%',
                position: 'relative',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '100%',
                  maxWidth: '25.313vw', //'486px',
                  paddingBottom: '10vh',
                }}
              >
                <h3 className={'fridayH3'}>{t.weddingPlannerSection1}</h3>
                <p className={'didacticP'}>{t.weddingPlannerSection2}</p>
              </div>
              <div>
                <img
                  className={styles.weddingPlanningPhoto}
                  loading="lazy"
                  src={`${process.env.CDN_PROVIDER_URL}/newassets/wedding_planner_card.jpg`}
                  alt="wedding_planner_card"
                />
              </div>
            </div>
          )}
        </div>

        <div className={styles.card}>
          <div className={styles.title}>
            <h3 className={'fridayH3'}>01</h3>
            <h3 className={'didacticH3'}>{t.weddingPlannerSection3}</h3>
          </div>
          <div className={styles.cardVideoAndContent}>
            <div className={styles.cardVideoWithFrame}>
              <div className={styles.videoFrame}>
                <motion.video
                  preload="none"
                  ref={refVideoCard1}
                  className={styles.video}
                  style={{ zIndex: 2 }}
                  controls
                  muted
                  loop
                  playsInline
                  poster={`${process.env.CDN_PROVIDER_URL}/newassets/video/Customize your website.jpg`}
                ></motion.video>
              </div>
            </div>
            <p className={'didacticP'}>{t.weddingPlannerSection4}</p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.title}>
            <h3 className={'fridayH3'}>02</h3>
            <h3 className={'didacticH3'}>{t.weddingPlannerSection5}</h3>
          </div>
          <div className={styles.cardVideoAndContent}>
            <div className={styles.cardVideoWithFrame}>
              <div className={styles.videoFrame}>
                <motion.video
                  preload="none"
                  ref={refVideoCard2}
                  className={styles.video}
                  style={{ zIndex: 3 }}
                  controls
                  autoPlay
                  muted
                  loop
                  playsInline
                  poster={`${process.env.CDN_PROVIDER_URL}/newassets/video/Planning.jpg`}
                ></motion.video>
              </div>
            </div>
            <p className={'didacticP'}>{t.weddingPlannerSection6}</p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.title}>
            <h3 className={'fridayH3'}>03</h3>
            <h3 className={'didacticH3'}>{t.weddingPlannerSection7}</h3>
          </div>
          <div className={styles.cardVideoAndContent}>
            <div className={styles.cardVideoWithFrame}>
              <div className={styles.videoFrame}>
                <motion.video
                  preload="none"
                  ref={refVideoCard3}
                  className={styles.video}
                  style={{ zIndex: 4 }}
                  controls
                  autoPlay
                  muted
                  loop
                  playsInline
                  poster={`${process.env.CDN_PROVIDER_URL}/newassets/video/RSVP and Invitations.jpg`}
                ></motion.video>
              </div>
            </div>
            <p className={'didacticP'}>{t.weddingPlannerSection8}</p>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.title}>
            <h3 className={'fridayH3'}>04</h3>
            <h3 className={'didacticH3'}>{t.weddingPlannerSection9}</h3>
          </div>
          <div className={styles.cardVideoAndContent}>
            <div className={styles.cardVideoWithFrame}>
              <div className={styles.videoFrame}>
                <motion.video
                  preload="none"
                  ref={refVideoCard4}
                  className={styles.video}
                  style={{ zIndex: 5 }}
                  controls
                  autoPlay
                  muted
                  loop
                  playsInline
                  poster={`${process.env.CDN_PROVIDER_URL}/newassets/video/Manage your guests.jpg`}
                ></motion.video>
              </div>
            </div>
            <p className={'didacticP'}>{t.weddingPlannerSection10}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardStackingSection;
